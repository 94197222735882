import React, { useState } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';
import {useRef} from 'react'

import { themeContext } from '../../Context.js';
import { useContext } from 'react';

const Contact = () => {

    const form = useRef();

    const [done,setDone] = useState(false)

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_yxdsgya', 'template_rwabz6m', form.current, 'oiCWL0L9VqMmbN8TI')
        .then((result) => {
            console.log(result.text);
            setDone (true);
        }, (error) => {
            console.log(error.text);
        });
    };

    
    const theme =useContext(themeContext);
    const darkMode = theme.state.darkMode;



  return (

    

   <div className="contact-from">
    <div className="w-left">
        <div className="awesome">
            <span style={{color:darkMode?'white':''}}>Get in Touch</span>
            <span>Contact me</span>

            <div className='blur s-blur1'
            style={{background:"#ABF1FF94"}}>

            </div>
        </div>
    </div>

    <div className="c-right">
        <form ref ={form } onSubmit ={sendEmail}>

            <input type="text" name='user_name' className="user"  placeholder='Name' />
            <input type="email" name='user_email' className='user' placeholder='Email' />
            <textarea name="message" className='user' placeholder='Message'></textarea>
            <input type="submit" value="send" className='button'/>
            <span>{done && "Thanks for contact in me !"}</span>

            <div

            className='blur c-blur1'
            style={{background:"var(--purple)"}}
            >

            </div>

        </form>
    </div>
   </div>
  )
}

export default Contact